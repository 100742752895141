import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../Reducers/LoaderProvider';

function Faq() {
  const { setLoading } = useLoader();
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }, [])
  const navigation=useNavigate()
  const faqItems = [
    {
      question: "How do I register my hotel?",
      answer: "Registering your hotel is simple. Go to the registration page, fill out the necessary details, and submit the required documents."
    },
    {
      question: "How can I update my hotel details?",
      answer: "You can update your hotel details by going to the 'Profile Manage' section and editing the relevant information."
    },
    {
      question: "What if I need to cancel a booking?",
      answer: "If you need to cancel a booking, go to the 'Bookings' section, select the booking you want to cancel, and click 'Cancel.'"
    },
    {
      question: "How do I add room amenities?",
      answer: "To add room amenities, go to the 'Room Manage' section, select the room, and then click on 'Add Amenities.'"
    },
    {
      question: "What should I do if I forget my password?",
      answer: "If you forget your password, click on the 'Forgot Password' link on the login page and follow the instructions to reset it."
    }
  ];

  return (
    <Box sx={{
      padding: { xs: "20px 80px", sm: "20px 80px", md: "20px 80px", lg: "20px 80px" },
      backgroundColor: "#F1F1F1",
      minHeight: "100vh",
    }}>
      <Box
        sx={{
          padding: { xs: "20px", md: "40px" },
          borderRadius: "10px",
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#000000",
            mb: 4,
          }}
        >
          Frequently Asked Questions (FAQs)
        </Typography>

        <Box sx={{ mb: 4 }}>
          {faqItems.map((item, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#000000",
                  mb: 1,
                }}
              >
                {index + 1} {item.question}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#666666",
                  pl: 2,
                  position: "relative",
                  "&::before": {
                    content: '"•"',
                    position: "absolute",
                    left: 0,
                    color: "#666666",
                  }
                }}
              >
                {item.answer}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={()=>navigation(-1)}
            variant="contained"
            sx={{
              bgcolor: '#E0E0E0',
              color: '#000000',
              textTransform: 'none',
              '&:hover': {
                bgcolor: '#D0D0D0',
              },
              px: 4,
              py: 1,
              borderRadius: '8px',
            }}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Faq;